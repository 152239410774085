/*------Partner / Customer page hero section------*/
.bg-imagesmb {
    background-image: url(https://narada19.github.io/new-design/img/main01.png);
    padding: 100px;
}

/*@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200; 0,300;1,200&display=swap");*/
a, b, body, center, code, div, dt, footer,
form, h1, h2, h3, h4, h5, h6, header,
html, i, img, label, li, menu, nav, p,
q, s, section, span,sub, ul,var {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    color: inherit;
}
body {
    font-size: 100%;
    font-family: Roboto, sans-serif !important;
    color: #434343;
    background-color: #fff;
}
nav.navbar {width:100%;}
.row {
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 0;
    margin-right: 0;
}

.text-gray{
    color: #666 !important;
}
.bg-gray{
    background: #00000012 !important;
}
img {
    max-width: 100%;
}
h1,
h2,
h3,
h4,
h5 {
    font-family: Roboto, sans-serif !important;
}
.navbar .dropdown-menu div[class*="col"] {
    margin-bottom: 1rem;
}
.navbar .dropdown-menu {
    border: none;
}

/*----------Mobile ---------------*/
.cWSO2MobileNav .back {
    color: #212a32;
    background:#ffffff;
}
.cWSO2MobileNav header {
    height: 60px;
    background-color: #ffffff;
    position: fixed;
    width: 100%;
    top: 0px;
    padding: 10px 0px;
    z-index: 99;
}
.cWSO2MobileNav header .logo-port {
    padding-left: 15px;
    width: 40%;
}
.cWSO2MobileNav header .logo-port img {
    height: 30px;
    /* width: 100px; */
}
/* MINI */
body.mmactive {
    overflow: hidden;
}
.mobile_menu {
    /* display: none; */
    position: relative;
}
.sm_menu_outer.active .mobile_menu {
    display: block;
}
.sm_menu_outer {
    height: calc(100vh - 60px);
    overflow-y: scroll;
    overflow-x: hidden;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: -9;
    width: 100%;
    opacity: 0;
    background-color: #212a32;
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    transition: opacity 0.2s;
    top:52px;
}
.sm_menu_outer.active {
    opacity: 1;
    z-index: 999;
}
.sm_menu_outer .mobile_menu a {
    color: #ffffff;
    display: block;
    padding: 20px 15px;
    /* text-transform: uppercase; */
    text-decoration: none;
}
/*Sub Menu anim */
.sm_menu_outer .mobile_menu li {
    list-style-type: none;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0);
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, #d7e2de 20%, #d7e2de 50%, #2a2a2a 80%, rgba(0, 0, 0, 0) 100%) 0 0 1 0;
    color: #ffffff;
}
/*Hamburger Animation */
#sm_menu_ham {
    cursor: pointer;
    float: right;
    height: 25px;
    position: fixed;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: all 0.5s ease-in-out 0s;
    -moz-transition: all 0.5s ease-in-out 0s;
    -o-transition: all 0.5s ease-in-out 0s;
    transition: all 0.5s ease-in-out 0s;
    width: 30px;
    top: 15px;
    right: 15px;
    z-index: 9999;
}
#sm_menu_ham span {
    background-color: #212a32;
    border-radius: 5px;
    display: block;
    height: 2px;
    left: 0;
    opacity: 1;
    position: absolute;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: all 0.25s ease-in-out 0s;
    -moz-transition: all 0.25s ease-in-out 0s;
    -o-transition: all 0.25s ease-in-out 0s;
    transition: all 0.25s ease-in-out 0s;
    width: 30px;
}
#sm_menu_ham span:nth-child(1) {
    top: 0;
}
#sm_menu_ham span:nth-child(2),
#sm_menu_ham span:nth-child(3) {
    top: 10px;
}
#sm_menu_ham span:nth-child(4) {
    top: 20px;
}
#sm_menu_ham.open span:nth-child(1) {
    left: 50%;
    top: 18px;
    width: 0;
}
#sm_menu_ham.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
}
#sm_menu_ham.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
#sm_menu_ham.open span:nth-child(4) {
    left: 50%;
    top: 18px;
    width: 0;
}
.sm_menu_outer.accordion .mobile_menu .submenu,
.sm_menu_outer.accordion .mobile_menu li.hasChild ul.submenu {
    display: none;
}
.sm_menu_outer.accordion .mobile_menu .hasChild > .submenu li {
    padding-left: 20px;
}
.sm_menu_outer.accordion .mobile_menu li.active > .submenu {
    right: auto;
}
.sm_menu_outer.accordion .mobile_menu li.hasChild > a {
    position: relative;
}
.sm_menu_outer.accordion .mobile_menu li.hasChild > a::before {
    position: absolute;
    content: '';
    /*background: url("") no-repeat scroll 50% center / 60% auto;*/
    background: no-repeat scroll 50% center / 60% auto ;
    right: 10px;
    top: 20px;
    width: 30px;
    height: 25px;
    transition: all 0.19s ease;
}
.sm_menu_outer.accordion .mobile_menu li.hasChild.active > a::before {
    transform: rotate(89deg);
    transition: all 0.19s ease;
}
.cSubmenuBlock h6 {
    color: #a3a3a3;
    margin-left: 0;
    font-weight: 600;
    /* border-bottom: 3px solid #ff7300; */
    display: inline-block;
    padding-bottom: 8px;
    margin-bottom: 14px;
    margin-top:20px !important;
    margin-top: 0;
    padding-top: 0;
    text-transform: uppercase;
    letter-spacing: 0.15rem;
}
.submenu , .mobile_menu {
    margin-left:0;
}
.submenu  {
    background:#35424d;
    padding-top: 20px;
    padding-bottom:20px;
}
.cMobileInput {
    position: absolute;
    z-index: 999999;
    right: 0;
    height: 46px;
    width: 60px;
    background: none;
    background-color: transparent;
    border: none;
    background-size: 30px;
    background-position: center;
    background-repeat: no-repeat;
}
.sm_menu_outer a.cContactButton {
    margin: 0;
    border-radius: 0;
}
/*---------End Mobile-------*/

/*------------- Main Slider --------------*/
.slider, .slide {
    height: 80vh;
}

.slide {
    position: relative;
}
.slide .slide__img {
    width: 100%;
    height: auto;
    overflow: hidden;
}

.extra-h1{
    font-size: 3.5rem;
}
@media (min-width: 992px) {
    .slide .slide__img {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
}
.slide .slide__img img {
    max-width: 100%;
    height: auto;
    opacity: 1 !important;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    transition: all 1s ease;
}
.slide .slide__content {
    position: absolute;
    top: 50%;
    left: 35%;
    transform: translate(-50%, -50%);
}
.slide .slide__content--headings {
    text-align: center;
    color: #fff;
    padding-top: 140px;
}

.slide .slide__content--headings h2 {
    font-size: 3rem;
    margin: 10px 0;
    line-height: 10vh;
    font-weight: bold;
}

.slide .slide__content--headings .mdf-h2 {
    font-size: 2rem;
    line-height: 8vh;
}

.slide .slide__content--headings p {
    font-size: 18px;
    line-height: 30px;
}

.slide .slide__content--headings .mdf-para {
    font-size: 16px;
    line-height: 25px;
}

.slide .slide__content--headings .animated {
    transition: all 0.5s ease;
}
.slider [data-animation-in] {
    opacity: 0;
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    transition: opacity 0.5s ease 0.3s;
}
.slick-dotted .slick-slider {
    margin-bottom: 30px;
}
.slick-dots {
    position: absolute;
    bottom: 25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
}
.slick-dots li {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}
.slick-dots li button {
    border: 0;
    display: block;
    outline: none;
    line-height: 0px;
    font-size: 0px;
    color: transparent;
    padding: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}
.simple-dots .slick-dots li {
    width: 20px;
    height: 20px;
}
.simple-dots .slick-dots li button {
    border-radius: 50%;
    background-color: white;
    opacity: 0.25;
    width: 20px;
    height: 20px;
}
.simple-dots .slick-dots li button:hover,
.simple-dots .slick-dots li button:focus {
    opacity: 1;
}
.simple-dots .slick-dots li.slick-active button {
    color: white;
    opacity: 0.75;
}
.stick-dots .slick-dots li {
    height: 3px;
    width: 50px;
}
.stick-dots .slick-dots li button {
    position: relative;
    background-color: white;
    opacity: 0.25;
    width: 50px;
    height: 3px;
    padding: 0;
}
.stick-dots .slick-dots li button:hover,
.stick-dots .slick-dots li button:focus {
    opacity: 1;
}
.stick-dots .slick-dots li.slick-active button {
    color: white;
    opacity: 0.75;
}
.stick-dots .slick-dots li.slick-active button:hover,
.stick-dots .slick-dots li.slick-active button:focus {
    opacity: 1;
}

.slick-arrow.slick-hidden {
    display: none;
}
.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    width: 60px;
    height: 100px;
    cursor: pointer;
    background: transparent;
    top: 40%;
    margin-top: -50px;
    font-size: 0;
    border: none;
    z-index: 2;
}
.slick-prev:before,
.slick-next:before,
.slick-prev:after,
.slick-next:after {
    content: '';
    position: absolute;
    background: #fff;
    height: 2px;
    width: 50px;
    top: 50%;
    left: 5px;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
.slick-prev:before,
.slick-next:before {
    margin-top: -22px;
}
.slick-prev:after,
.slick-next:after {
    margin-top: 22px;
    margin-top: 2.2rem;
}
.slick-prev:hover:before,
.slick-next:hover:before {
    margin-top: -18px;
    margin-top: -1.8rem;
}
.slick-prev:hover:after,
.slick-next:hover:after {
    margin-top: 18px;
    margin-top: 1.8rem;
}
.slick-prev {
    left: -7px;
    left: -0.7rem;
}
.slick-prev:before {
    -webkit-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
    transform: rotate(-60deg);
}
.slick-prev:after {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
}
.slick-prev:hover:before {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.slick-prev:hover:after {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.slick-next {
    right: -7px;
    right: -0.7rem;
}
.slick-next:before {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
}
.slick-next:after {
    -webkit-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
    transform: rotate(-60deg);
}
.slick-next:hover:before {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.slick-next:hover:after {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.slick-prev,
.slick-next {
    opacity: 0.85;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=85);
    -webkit-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out;
}
.slick-prev:before,
.slick-next:before,
.slick-prev:after,
.slick-next:after {
    background-color: #e30101;
    height: 15px;
    height: 0.3rem;
    width: 70px;
    width: 1.5rem;
}
.slick-prev:before,
.slick-next:before {
    margin-top: 10px;
    /*margin-top: -0.2rem;*/
}
.slick-prev:after,
.slick-next:after {
    margin-top: 20px;
    margin-top: 1.5rem;
}
.slick-prev:hover:before,
.slick-next:hover:before {
    margin-top: -20px;
    margin-top: -0.2rem;
}
.slick-prev:hover:after,
.slick-next:hover:after {
    margin-top: 20px;
    margin-top: 0.2rem;
}
.slick-prev:hover:active,
.slick-next:hover:active {
    opacity: 1;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
}
.slick-prev {
    left: 10px;
    left: 1rem;
}
.slick-prev:before {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.slick-prev:after {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.slick-prev:hover:before {
    margin-top: -27px;
    margin-top: -0.9rem;
    -webkit-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
    transform: rotate(-60deg);
}
.slick-prev:hover:after {
    margin-top: 27px;
    margin-top: 0.3rem;
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
}
.slick-next {
    right: 10px;
    right: 1rem;
}
.slick-next:before,
.slick-next:after {
    left: auto;
    right: 0;
}
.slick-next:before {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.slick-next:after {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.slick-next:hover:before {
    margin-top: -27px;
    margin-top: -0.9rem;
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
}
.slick-next:hover:after {
    margin-top: 27px;
    margin-top: 0.3rem;
    -webkit-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
    transform: rotate(-60deg);
}

.crop-text-4 {
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
/* /////////// IMAGE ZOOM /////////// */
@-webkit-keyframes zoomInImage {
    from {
        transform: scale3d(1, 1, 1);
    }
    to {
        transform: scale3d(1.1, 1.1, 1.1);
    }
}
@keyframes zoomInImage {
    from {
        transform: scale3d(1, 1, 1);
    }
    to {
        transform: scale3d(1.1, 1.1, 1.1);
    }
}
.zoomInImage {
    -webkit-animation-name: zoomInImage;
    animation-name: zoomInImage;
}
@-webkit-keyframes zoomOutImage {
    from {
        transform: scale3d(1.1, 1.1, 1.1);
    }
    to {
        transform: scale3d(1, 1, 1);
    }
}
@keyframes zoomOutImage {
    from {
        transform: scale3d(1.1, 1.1, 1.1);
    }
    to {
        transform: scale3d(1, 1, 1);
    }
}
.zoomOutImage {
    -webkit-animation-name: zoomOutImage;
    animation-name: zoomOutImage;
}

@media(max-width: 1366px) {
    .slide .slide__content--headings h2 {
        line-height: 11vh;
    }
    .slide .slide__content--headings h2 {
        font-size: 3.5rem;
        margin: 10px 0;
        line-height: 8vh;
        font-weight: bold;
    }
}
/*------------- End Main Slider ------------*/

@media screen and (max-width: 1300px) {
    .row {
        margin-left: 0;
        margin-right: 0;
    }
}
h1 {
    font-size: 2.4rem;
    line-height: 3.35rem;
    font-weight: 400;
    letter-spacing: 0.1rem;
    margin: 0 0 1.5rem;
    display: block;
}
h2 {
    font-size: 1.75rem;
    line-height: 2.75rem;
    font-weight: 300;
    letter-spacing: 0.05rem;
    margin: 0 0 1.5rem;
    display: block;
}
h3 {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 400;
    letter-spacing: 0.05rem;
    margin: 0 0 1.5rem;
    display: block;
}
h4 {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 500;
    letter-spacing: 0.05rem;
    margin: 0 0 1.5rem;
    display: block;
}
h5 {
    font-size: 1rem;
    line-height: 1.6rem;
    font-weight: 500;
    letter-spacing: 0.05rem;
    margin: 0 0 1.25rem;
    display: block;
}
p {
    font-size: 1rem;
    line-height: 1.6rem;
    font-weight: 400;
    letter-spacing: 0.018rem;
    margin: 0 0 1.5rem;
}
ul {
    margin: 0 0 1.5rem 2rem;
}
ul li {
    font-size: 1rem;
    line-height: 1.6rem;
    font-weight: 400;
    letter-spacing: 0.01rem;
    margin: 0 0 0;
}
a {
    color: #ff7300;
}
a,
a:active,
a:focus,
a:visited {
    color: inherit;
    text-decoration: none;
    transition: all 0.2s;
}
p a {
    text-decoration: none;
    color: #ff7300;
}
p a:hover {
    text-decoration: underline;
}
a:hover {
    color: #ff7300;
    transition: all 0.2s;
    border-bottom: 0;
}
a.cButton {
    border-radius: 3px;
    margin-right: 10px;
    display: inline-block;
    font-size: 0.9rem;
    font-weight: 500;
    padding: 8px 25px;
    background-color: #595b5f;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    transition: all 0.2s;
    text-decoration: none;
    text-align: center;
}
a.cButton:hover {
    background-color: #292929;
    color: #ff7300;
    transition: all 0.2s;
    text-decoration: none;
}
section {
    padding: 4rem 0;
}
.cDarkGreyBG {
    background-color: #212a32;
    color: #eee;
}
.cDarkGreyBG h1,
.cDarkGreyBG h2,
.cDarkGreyBG h3,
.cDarkGreyBG p,
.cDarkGreyBG ul li {
    font-weight: 300;
}
.cDarkGreyBG a:hover {
    color: #ff7300;
}
.cHighlightedRow {
    margin: -8.75rem 0 !important;
    position: relative;
    display: block;
}
.cHighlighted {
    padding: 3rem 4rem;
    -webkit-box-shadow: 2px 5px 10px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 2px 5px 10px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 2px 5px 10px 0 rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 1200px) {
    a.cButton {
        margin-bottom: 15px;
    }
}
@media screen and (max-width: 992px) {
    .cHighlightedRow {
        margin-bottom: 0;
    }
    .cHighlighted {
        padding: 2rem 2.5rem;
        margin-bottom: 1rem;
    }
}
@media screen and (max-width: 768px) {
    .cHighlighted {
        padding: 2rem 2.5rem;
    }
}
@media screen and (max-width: 575px) {
    .cHighlighted {
        padding: 1.5rem 1.25rem;
    }
    section {
        padding: 2.5rem 0.5rem;
    }
    h1 {
        font-size: 2rem;
        line-height: 2.25rem;
    }
    h2 {
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 500;
    }
    h3 {
        font-size: 1.25rem;
        line-height: 1.75rem;
        font-weight: 500;
    }
    h4 {
        font-size: 1.1rem;
        line-height: 1.6rem;
        font-weight: 500;
    }
    section {
        padding: 5rem 0.5rem;
    }
}

code {
    font-family: monospace, monospace;
    font-size: 14px;
    padding: 20px;
    line-height: 24px;
}
ul li {
    font-family: "Titillium Web", sans-serif;
    font-size: 1rem;
    line-height: 1.6rem;
    font-weight: 400;
    letter-spacing: 0.01rem;
    margin: 0 0 0;
}
#sm_menu_ham,
.cWSO2MobileNav,
.sm_menu_outer.accordion {
    display: none;
}
.navbar {
    background: #fff;
    padding-top: 0;
    padding-bottom: 0;
    box-shadow: 1px 3px 4px 0 #adadad33;
}
.navbar-light .navbar-brand {
    color: #2196f3;
}
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
    color: #b7222a !important;
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: #fff;
}
.navbar-light .navbar-nav .nav-link {
    padding-top: 10px;
    padding-bottom: 0;
    transition: 0.3s;
    padding-left: 15px;
    padding-right: 15px;
    line-height: 47px;
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    transition: 0.3s;
}
.dropdown-item:focus,
.dropdown-item:hover {
    text-decoration: none;
}
.dropdown-item:hover {
    color: #fff;
    text-decoration: none;
    background-color: #212a32 !important;
}
.dmenu.cActiveMenu {
    background-color: #212a32;
}
.sm-menu {
    border-radius: 0;
    border: 0;
    top: 97%;
    box-shadow: rgba(173, 173, 173, 0.2) 1px 3px 4px 0;
}
.dropdown-item {
    color: #3c3c3c;
}
.dropdown-item.active,
.dropdown-item:active {
    text-decoration: none;
    background-color: #2196f3;
}
.dropdown-item:focus,
.dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background-color: transparent;
}
.navbar-toggler {
    outline: 0 !important;
}
.megamenu-li {
    position: static;
}
.cLoginMenu,
.megamenu {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    padding: 15px;
    background-color: rgba(33, 42, 50, 0.98);
    border-top: none !important;
}
.cLoginMenu {
    width: 260px;
    margin-left: -154px;
    margin-top: 8px;
}
.cLoginMenu ul {
    margin: 0;
}
.megamenu h6 {
    margin-left: 21px;
}
.megamenu i {
    width: 20px;
}
.cHighlightedRow {
    display: flex;
}
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9 {
    float: left;
}
a.navbar-brand {
    width: 100px;
    padding: 10px 0;
}
.cMenuMargin {
    margin-right: 50px;
    float: right;
}
.cUserLogin.dropdown-toggle,
.cUserLogin.dropdown-toggle::after {
    content: none;
    width: 53px;
    height: auto;
    margin-top: 3px;
}
.dropdown-menu.megamenu.sm-menu.border-top {
    margin-top: 0;
    height: auto;
}
.cSelected {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 12px 0 12px;
    border-color: #212a32 transparent transparent transparent;
    line-height: 0;
    display: inline-block;
    float: left;
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    margin-top: -24px;
}
.cSelected.cCompany {
    margin-left: 440px;
}
.cSelected.cSolutions {
    margin-left: 530px;
}
.cSelected.cLoginMenuBlock {
    margin-left: 155px;
}
a.cSingleLink,
a.dropdown-toggle {
    color: #000 !important;
    position: relative;
}
a.cSingleLink:hover,
a.dropdown-toggle:hover {
    color: #b7222a !important;
}
a.cSingleLink::before,
a.dropdown-toggle::before {
    content: "";
    position: absolute;
    width: 0;
    height: 5px;
    left: 50%;
    top: -1px;
    background-color: #b7222a;
    transition: all ease-in-out 0.2s;
}
a.cSingleLink:hover::before,
a.dropdown-toggle:hover::before {
    width: 100%;
    left: 0;
}
.row.cInlineMenuRow {
    width: 100% !important;
}
.cActiveMenu a.dropdown-toggle {
    color: #b7222a !important;
}
.dropdown-menu.megamenu h6 {
    color: #a3a3a3;
    margin-left: 0;
    font-weight: 600;
    display: inline-block;
    padding-bottom: 8px;
    margin-bottom: 14px;
    margin-top: 0;
    padding-top: 0;
    text-transform: uppercase;
    letter-spacing: 0.15rem;
}
.megamenu .border-right {
    border-right: 1px solid #4e6273 !important;
}
.megamenu a.nav-link.dropdown-item {
    padding: 0;
    line-height: 30px;
    height: auto;
    font-size: 15px;
}
.navbar-light .navbar-nav .nav-link {
    color: #fff;
}
.navbar-light .navbar-nav .nav-link:hover {
    color: #b7222a !important;
}
.megamenu .border-right {
    border: none !important;
}
.ExpIcon img {
    width: 24px;
}
.cMenuHighlightBox {
    width: 100%;
    display: inline-block;
    background-color: transparent;
}
.cMenuHighlightBox a {
    display: inline-block;
    width: 100%;
    padding: 0;
    background: 0 0;
    border: 1px solid transparent;
}
.cMenuHighlightBox a:hover {
    display: inline-block;
    width: 100%;
}
.cMenuHighlightBox img {
    border: 1px solid transparent;
}
.cMenuHighlightBox a:hover img {
    border: 1px solid #b7222a;
}
.cMenuHighlightBox h3 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: #212a32;
}
.cMenuHighlightBox a:hover h3 {
    color: #d7e2de;
}
.cMenuHighlightBox span {
    border-radius: 3px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: 0;
    padding: 6px 18px;
    background-color: #465867;
    color: #d7e2de;
}
.cMenuHighlightBox a:hover span {
    background-color: #d7e2de;
    color: #212a32;
}
.navbar-collapse {
    margin-top: -5px;
}
a.cContactButton {
    border-radius: 3px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #fff !important;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    display: inline-block;
    padding: 6px 18px;
    background-color: #b7222a;
    margin-top: 18px;
    margin-right: 20px;
}
a.cContactButton:hover {
    color: #fff !important;
    text-decoration: none !important;
    background: #000 !important;
}
a.cContactButton {
    position: relative;
    top: auto;
}
.content .btn {
    background: #b7222a;
    color: #fff;
    font-weight: 600;
    border-color: #b7222a;
}
.social-media li {
    display: inline;
}
#portfolio {
    margin: 1rem 0;
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1rem;
    -moz-column-gap: 1rem;
    column-gap: 1rem;
    -webkit-column-width: 33.33333333333333%;
    -moz-column-width: 33.33333333333333%;
    column-width: 33.33333333333333%;
}
.tile {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
}
.scale-anm {
    transform: scale(1);
}
.tile img {
    max-width: 100%;
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
}
.btn-gallary {
    font-size: 1rem;
    font-weight: 400;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    line-height: normal;
    padding: 0.5rem 1rem;
    margin: 0;
    height: auto;
    border: 1px solid;
    vertical-align: middle;
    -webkit-appearance: none;
    color: #fff;
    background-color: #b7222a;
}
.btn-gallary:hover {
    text-decoration: none;
    color: #fff;
    background-color: #dc0c17e3;
}
.btn-gallary:focus {
    outline: 0;
    border-color: var(--darken-2);
    box-shadow: 0 0 0 3px var(--darken-3);
    background-color: #dc0c17e3;
}
::-moz-focus-inner {
    border: 0;
    padding: 0;
}
.cliens img {
    max-width: 100%;
    transition: all 0.4s ease-in-out;
    display: inline-block;
    padding: 0;
    -webkit-filter: grayscale(100);
    filter: grayscale(100);
}
.cliens img:hover {
    -webkit-filter: none;
    filter: none;
    transform: scale(1.1);
}
.clearfix::after,
.clearfix::before {
    content: "";
    display: table;
}
.clearfix::after {
    clear: both;
}
.clearfix {
    zoom: 1;
}
.float_left {
    float: left;
}
.float_right {
    float: right;
}
.bbb_background {
    background-color: #e0e0e0 !important;
}
.button {
    display: inline-block;
    background: #0e8ce4;
    border-radius: 5px;
    height: 48px;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.button a {
    display: block;
    font-size: 18px;
    font-weight: 400;
    line-height: 48px;
    color: #fff;
    padding-left: 35px;
    padding-right: 35px;
}
.button:hover {
    opacity: 0.8;
}
.bbb_background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.bbb_container {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    padding-right: 40px;
}
.bbb_title {
    padding-right: 50px;
}
.bbb_text {
    margin-top: 17px;
}
.bbb_text p:last-child {
    margin-bottom: 0;
}
.bbb_slider_nav {
    margin-top: 27px;
}
.bbb_nav {
    display: inline-block;
    width: 36px;
    height: 36px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    margin-right: 16px;
    background-color: #2d8ce4;
}
.bbb_nav:last-child {
    margin-right: 0;
}
.bbb_nav i {
    line-height: 36px;
    color: #fff;
    font-size: 18px;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.bbb_nav:hover i {
    color: #474747;
}
.bbb_item {
    background: #fff;
    border-radius: 1px;
    padding: 25px;
}
.bbb_image {
    width: 100%;
    height: 212px;
}
.bbb_image img {
    width: auto !important;
    max-width: 100% !important;
}
.bbb_content {
    margin-top: 24px;
}
.bbb_name {
    float: left;
}
.bbb_name a {
    font-size: 16px;
    color: #000;
}
.bbb_name a:hover {
    color: #0e8ce4;
}
.bbb_item.is_new .bbb_new {
    display: block;
}
.bbb_new {
    background: #0e8ce4;
}

@media only screen and (max-width: 991px) {
    .bbb_slider_container {
        margin-top: 15px;
    }
}

@media (max-width: 768px) {
    .counter {
        display: block;
        font-size: 50px;
        font-weight: 700;
        color: #666;
        line-height: 28px;
        font-family: Oswald, sans-serif;
    }
    .cAnalystReports .cCitations {
        color: #212a32;
        padding: 20px 15px;
        box-shadow: none;
        border-radius: 0;
        background-color: #d7e2de;
        border-top: none;
        border-bottom: none;
        margin: 160px 0 0 0;
    }
}


/*--------- Map Style ----------*/
/* Original pulsing dots by sharla */
@-webkit-keyframes pulse {
    0% { box-shadow: 0px 0px 0px 0px #b7222a;}
    100% {box-shadow: 0px 0px 0px 7px rgba(52, 106, 180, 0);}
}
@keyframes pulse {
    0% {box-shadow: 0px 0px 0px 0px #b7222a;}
    100% {
        box-shadow: 0px 0px 0px 7px rgba(52, 106, 180, 0);
    }
}

.img-map {
    width: 100%;
    max-width: 100%;
}

#map {
    position: relative;
    max-width: 1280px;
    margin: 0 auto;
}

.dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    -webkit-animation: pulse 1.5s infinite ease-out;
    animation: pulse 1.5s infinite ease-out;
    background: #b7222a;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
}
.dot:before {
    content: "";
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    background: rgba(255, 255, 255, 0.4);
}
.dot:nth-child(odd) {
    -webkit-animation: pulse 1.5s infinite ease-out 0.3s;
    animation: pulse 1.5s infinite ease-out 0.3s;
}
.dot-15 {
    top: 60.3%;
    left: 76.1%;
}
.dot-18 {
    top: 55.3%;
    left: 69.6%;
}
.dot-21 {
    top: 79%;
    left: 85%;
}
@media (min-width: 768px) {
    .dot {
        width: 8px;
        height: 8px;
    }
    .dot:before {
        width: 4px;
        height: 4px;
    }
    @-webkit-keyframes pulse {
        0% {
            box-shadow: 0px 0px 0px 0px #b7222a;
        }
        100% {
            box-shadow: 0px 0px 0px 10px rgba(52, 106, 180, 0);
        }
    }
    @keyframes pulse {
        0% {
            box-shadow: 0px 0px 0px 0px #b7222a;
        }
        100% {
            box-shadow: 0px 0px 0px 10px rgba(52, 106, 180, 0);
        }
    }
}
/*-----------End Map style-------------*/


.Exp-serach {
    position: absolute;
    width: 0%;
    min-width: 50px;
    height: 50px;
    float: right;
    overflow: hidden;
    -webkit-transition: width 0.3s;
    -moz-transition: width 0.3s;
    transition: width 0.3s;
    -webkit-backface-visibility: hidden;
    right: 0;
    bottom: 7px;
}
.ExpInput {
    background: #fff none repeat scroll 0 0;
    border: medium none;
    font-size: 20px;
    color: #444;
    height: 36px;
    margin: 0;
    outline: medium none;
    padding: 0 0 0 20px;
    position: absolute;
    right: 0;
    top: 13px;
    z-index: 10;
    width: 300px !important;
    font-size: 1rem;
    line-height: 1.6rem;
    color: #292929;
}
input[type="search"].ExpInput {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
}
.ExpIcon,
.Expbtn {
    width: 36px;
    height: 36px;
    display: block;
    position: absolute;
    right: 0;
    top: 13px;
    padding: 0;
    margin: 0;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
}
.Expbtn {
    background: #fff;
    opacity: 0;
    color: transparent;
    color: red;
    border: none;
    outline: 0;
    z-index: -1;
}
.ExpIcon {
    z-index: 90;
    font-size: 22px;
    font-family: icomoon;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    line-height: 46px;
}
.ExpIcon:before {
    content: "";
}
.Exp-serach.Exp-serach-open,
.no-js .Exp-serach {
    width: 100%;
}
.Exp-serach.Exp-serach-open .ExpIcon,
.no-js .Exp-serach .ExpIcon {
    background: #ccc;
    color: #fff;
    z-index: 11;
}
.Exp-serach.Exp-serach-open .ExpInput {
    border: 1px solid #ccc;
}

@media screen and (max-width: 1130px) {
    .cMenuPositionRelative {
        width: auto;
    }
    .navbar-nav {
        margin-right: 0 !important;
    }
}
@media screen and (max-width: 1025px) {
    .Exp-serach {
        bottom: 10px;
    }
    .ExpIcon,
    .Expbtn {
        height: 46px;
        top: 5px;
        right: 7px;
    }
    .Exp-serach.Exp-serach-open .ExpIcon,
    .no-js .Exp-serach .ExpIcon {
        right: 0;
        top: 0;
        padding: 5px 5px 0;
    }
    #sm_menu_ham,
    .cWSO2MobileNav,
    .sm_menu_outer.accordion {
        display: block;
    }
    .cWSO2DeskNav {
        display: none;
    }
}
@media screen and (max-width: 1025px) {
    .navbar-brand {
        padding: 0;
    }
    .navbar-light .navbar-nav .nav-link {
        padding-left: 5px;
        padding-right: 5px;
    }
    .cMenuPositionRelative {
        width: auto !important;
    }
    .navbar-nav.mr-auto.mt-2.mt-lg-0.float-md-right {
        float: left !important;
    }
    .cMenuPositionRelative {
        display: inline-block;
        width: 100% !important;
    }
    .cMenuMargin {
        float: left !important;
    }
    .navbar-light .navbar-nav .nav-link {
        border-bottom: 1px solid #3a4854;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.1rem;
    }
    ul.navbar-nav {
        margin: 0 !important;
        width: 100% !important;
    }
    .dropdown-toggle::after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
        font-size: 50px;
        float: right;
        margin-top: 14px;
    }
    .cSelected.cCompany {
        margin-left: 0;
    }
    .cSelected.cSolutions {
        margin-left: 0;
    }
    .cSelected.cLoginMenuBlock {
        margin-left: 0;
    }
    .cLoginMenu {
        display: block;
        width: 100%;
        margin: 0;
        margin-top: -2px;
    }
    a.cLoginLink {
        margin-top: 0;
    }
}
.cDarkGreyBG {
    background-color: #212a32;
    padding: 4rem 0;
    padding-bottom: 15rem;
}
a:hover {
    color: #00a4b6;
    text-decoration: none;
}
.social-link {
    width: 30px;
    height: 30px;
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    border-radius: 50%;
    transition: all 0.3s;
    font-size: 0.9rem;
}
.social-link:focus,
.social-link:hover {
    background: #ddd;
    text-decoration: none;
    color: #555;
}
.bg-skyblue {
    background: #f4fbfb;
}
.counter-box {
    display: block;
    background: #f6f6f6;
    padding: 40px 20px 37px;
    text-align: center;
}
.counter-box p {
    margin: 5px 0 0;
    padding: 0;
    color: #909090;
    font-size: 18px;
    font-weight: 500;
}
.counter-box i {
    font-size: 60px;
    margin: 0 0 15px;
    color: #d2d2d2;
}
.counter {
    display: block;
    font-size: 70px;
    font-weight: 700;
    color: #666;
    line-height: 28px;
    font-family: Oswald, sans-serif;
}
.cDesktopHeader {
    display: block;
}
a.cButton {
    margin-right: 10px;
    display: inline-block;
    border-radius: 3px;
    font-size: 16px;
    font-weight: 500;
    padding: 7px 17px;
    background-color: #b7222a;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.25px;
    transition: all 0.2s;
    text-decoration: none;
    text-align: center;
}
a.cButton:hover {
    background-color: #b7222a;
    color: #000;
    text-decoration: none;
}
.cDarkGreyBG {
    background-color: #212a32;
}
.cDarkGreyligBG {
    background-color: #212a32e6;
}
.cHomepageHeader {
    padding: 0 0 0;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
}
.site_logo img {
    max-width: 12rem;
}
.cHomepageHeader h1 {
    font-size: 55px;
    font-weight: 200;
    color: #fff;
    line-height: 65px;
    margin: 0 0 20px;
    text-align: left;
    margin-bottom: 40px;
}
.cAnalystReports .cCitations {
    color: #212a32;
    padding: 20px 15px;
    box-shadow: none;
    border-radius: 0;
    background-color: #d7e2de;
    border-top: none;
    border-bottom: none;
}
.cAnalystReports .cCitations h3 {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    margin: 0 0 7px;
}
.cAnalystReports .cCitations h2 {
    font-size: 30px;
    line-height: 36px;
    font-weight: 700;
    margin: 0 0 7px;
    letter-spacing: 0.5px;
}
.cAnalystReports .cCitations h4 {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    margin: 0;
    max-height: 90px;
}
.cAnalystReports .cCitations .cReport {
    min-height: 160px;
}
.cAnalystReports .cCitations a {
    display: block;
}
.cAnalystReports .cCitations a:hover {
    text-decoration: none;
    color: #000;
}
.cAnalystReports .cFrorresterAPIM,
.cAnalystReports .cFrorresterCIAM,
.cAnalystReports .cKuppingercoleCIAM {
    padding: 20px 30px 20px;
    border-right: solid 1px #465867;
}
.cAnalystReports .cFrorresterCIAM {
    border-right: 0;
}
.cAnalystReports .cCTA {
    border-radius: 3px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #fff;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: 21px;
    padding: 6px 12px;
    background-color: #465867;
    margin-top: 25px;
}
.cAnalystReports a:hover .cCTA {
    background-color: #000;
    color: #fff;
}
.cProductsSolutions {
    padding: 60px 0 60px;
}
.cProductCard {
    background-color: transparent;
    border-radius: 0;
    border: 0;
}
.cProductCard .card-body {
    padding: 0 0 50px;
}
.cProductCard .card-body .card-title {
    font-size: 14px;
    font-weight: 300;
    line-height: 25px;
    margin-bottom: 1rem;
    max-height: 120px;
    padding: 0 10px 0 10px;
}
.cProductCard .card-body .card-text {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    min-height: 28px;
}
.cProductCard .card-body .card-list {
    margin: 0;
    padding: 0;
    list-style: none;
}
.cProductCard .card-body .card-list li {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    padding: 7px 0;
    border-bottom: solid 0.5px #fff;
}
.cProductCard .card-body .card-list li a {
    color: #b7222a;
}
.cProductCard .cCardIcon {
    min-height: 60px;
}
.cProductCard .card-img-top {
    max-width: 188px;
}
.cProductCard .card-img-top.cSolutions {
    max-width: 258px;
}
.carousel-fade .carousel-item {
    opacity: 0;
    transition-duration: 0.6s;
    transition-property: opacity;
}
.carousel-fade .carousel-item.active {
    opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    opacity: 0;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .carousel-item.active {
    transform: translateX(0);
    transform: translate3d(0, 0, 0);
}
.crop-text-5 {
    -webkit-line-clamp: 5;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
.modal-confirm {
    color: #636363;
    width: 325px;
    font-size: 14px;
}
.modal-confirm .modal-content {
    padding: 20px;
    border-radius: 5px;
    border: none;
}
.modal-confirm .modal-header {
    border-bottom: none;
    position: relative;
}
.modal-confirm h4 {
    text-align: center;
    font-size: 26px;
    margin: 30px 0 -15px;
}
.modal-confirm .btn,
.modal-confirm .form-control {
    min-height: 40px;
    border-radius: 3px;
}
.modal-confirm .modal-footer {
    border: none;
    text-align: center;
    border-radius: 5px;
    font-size: 13px;
}
.modal-confirm .icon-box {
    color: #fff;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: -70px;
    width: 95px;
    height: 95px;
    border-radius: 50%;
    z-index: 9;
    background: #b7222a;
    padding: 15px;
    text-align: center;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}
.modal-confirm .icon-box i {
    font-size: 58px;
    position: relative;
    top: 3px;
}
.modal-confirm.modal-dialog {
    margin-top: 80px;
}
.modal-confirm .btn {
    color: #fff;
    border-radius: 4px;
    background: #b7222a;
    text-decoration: none;
    transition: all 0.4s;
    line-height: normal;
    border: none;
}
.modal-confirm .btn:focus,
.modal-confirm .btn:hover {
    background: #b7222a;
    outline: 0;
}
.cAnalystReports {
    position: relative;
    z-index: 10;
}
.footer-bs {
    background-color: #232323;
    padding: 60px 40px;
    color: rgba(255, 255, 255, 1);
    margin-bottom: 0;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 6px;
}
.footer-bs .footer-brand,
.footer-bs .footer-nav,
.footer-bs .footer-ns,
.footer-bs .footer-social {
    padding: 10px 25px;
}
.footer-bs .footer-nav,
.footer-bs .footer-ns,
.footer-bs .footer-social {
    border-color: transparent;
}
.footer-bs .footer-brand h2 {
    margin: 0 0 10px;
}
.footer-bs .footer-brand p {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
}
.footer-bs .footer-nav h4 {
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom: 10px;
}
.footer-bs .footer-nav ul.list {
    list-style: none;
    padding: 0;
}
.footer-bs .footer-nav ul.list li {
    padding: 5px 0;
}
.footer-bs .footer-nav ul.list a {
    color: rgba(255, 255, 255, 0.8);
}
.footer-bs .footer-nav ul.list a:hover {
    color: rgba(255, 255, 255, 0.6);
    text-decoration: none;
}
.footer-bs .footer-social ul {
    list-style: none;
    padding: 0;
}
.footer-bs .footer-social h4 {
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 3px;
}
.footer-bs .footer-social li {
    padding: 5px 4px;
}
.footer-bs .footer-social a {
    color: rgba(255, 255, 255, 1);
}
.footer-bs .footer-social a:hover {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
}
.footer-bs .footer-ns h4 {
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom: 10px;
}
.footer-bs .footer-ns p {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
}

/*-----------------------*/

.service-element, .case-study-element {
    background: #fff;
    padding: 5.375em 3.125em 5.375em 3.125em;
}

.hover-element {
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
}

.service-element .hover-element__initial, .case-study-element .hover-element__initial {
    z-index: 2;
    position: relative;
}

.hover-element * {
    transition: 0.3s ease;
    -webkit-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
}

.hover-element:hover .hover-element__reveal, .hover-element.hover--active .hover-element__reveal {
    opacity: 1;
}

.service-element .hover-element__reveal, .case-study-element .hover-element__reveal {
    z-index: 1;
}
.hover-element .hover-element__reveal {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
}
.hover-element * {
    transition: 0.3s ease;
    -webkit-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
}
[data-overlay="7"]:before {
    opacity: 0.7;
}

[data-overlay]:before {
    position: absolute;
    content: '';
    background: #242a2d;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
}
.service-element .background-image-holder, .case-study-element .background-image-holder {
    transition: 0.2s ease-out;
    -webkit-transition: 0.2s ease-out;
    -moz-transition: 0.2s ease-out;
}

.background-image-holder1{
    background: url(https://narada19.github.io/new-design/img/service1.jpg);
    opacity: 1;
}

.background-image-holder2{
    background: url(https://narada19.github.io/new-design/img/service2.jpg);
    opacity: 1;
}

.hover-element * {
    transition: 0.3s ease;
    -webkit-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
}
.background-image-holder {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: cover !important;
    background-position: 50% 50% !important;
    z-index: 0;
    transition: 0.3s linear;
    -webkit-transition: 0.3s linear;
    -moz-transition: 0.3s linear;
    opacity: 0;
    background: #242a2d;
}

.service-element:hover .hover-element__initial *, .case-study-element:hover .hover-element__initial *, .service-element.hover--active .hover-element__initial *, .case-study-element.hover--active .hover-element__initial * {
    color: #fff;
}

.service-element h3 {
    margin: 0;
}
.hover-element * {
    transition: 0.3s ease;
    -webkit-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
}



.login-container{
    margin-top: 5%;
    margin-bottom: 5%;
}
.login-logo{
    position: relative;
    margin-left: -41.5%;
}
.login-logo img{
    position: absolute;
    width: 20%;
    margin-top: 0;
    background: #282726;
    border-radius: 4.5rem;
    padding: 5%;
}
.login-form-1{
    padding: 9%;
    background:#212a32e6;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
}
.login-form-1 h3{
    text-align: center;
    margin-bottom:12%;
    color:#fff;
}
.login-form-2{
    padding: 9%;
    background: #b7222a;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
}
.login-form-2 h3{
    text-align: center;
    margin-bottom:12%;
    color: #fff;
}
.btnSubmit{
    font-weight: 600;
    width: 50%;
    color: #282726;
    background-color: #fff;
    border: none;
    border-radius: 1.5rem;
    padding:2%;
}
.btnForgetPwd{
    color: #fff;
    font-weight: 600;
    text-decoration: none;
}
.btnForgetPwd:hover{
    text-decoration:none;
    color:#fff;
}

.btn-gray{
    background: #666666 !important;
    border: 1px solid #666666 !important;
}
.requie-star{
    color: #b21f2d !important;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    background: #b7222a !important;
    color: #fff !important;
    border-color: #b7222a !important;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgb(167 8 8 / 50%) !important;
}
/*-----------------------*/



@media screen and (max-width: 1366px) {
    .slide .slide__content--headings h2 {
        line-height: 11vh;
    }
}
@media screen and (max-width: 1200px) {
    .cProductCard .card-body .card-title {
        min-height: 102px;
    }
    .cAnalystReports .cCitations h3 {
        font-size: 18px;
        line-height: 26px;
        font-weight: 400;
        margin: 0 0 7px;
        min-height: 50px;
    }
    .cAnalystReports .cCitations h2 {
        font-size: 30px;
        line-height: 36px;
        font-weight: 700;
        margin: 0 0 7px;
        letter-spacing: 0.5px;
        min-height: 70px;
    }
    .cAnalystReports .cCitations .cReport {
        min-height: 230px;
    }
}
@media screen and (max-width: 1100px) {
    .cAnalystReports .cCitations .cReport {
        min-height: 250px;
    }
}
@media screen and (max-width: 992px) {
    body {
        background-size: 300%;
    }
    .cAnalystReports .cFrorresterAPIM,
    .cAnalystReports .cFrorresterCIAM,
    .cAnalystReports .cKuppingercoleCIAM {
        padding: 20px 30px 20px;
        border-right: 0;
        border-bottom: solid 1px #465867;
    }
    .cAnalystReports .cFrorresterCIAM {
        border-bottom: 0;
    }
    .cAnalystReports .cCitations .cReport {
        min-height: auto;
        margin-bottom: 20px;
    }
    .cAnalystReports .cCitations h3 {
        min-height: auto;
    }
    .cAnalystReports .cCitations h2 {
        min-height: auto;
    }
    .cProductCard {
        background-color: #212a32;
        border-radius: 0;
        border: 0;
        margin: 15px 0;
        padding: 30px 30px 20px;
    }
    .cProductCard .card-body {
        padding: 0;
    }
    .cProductsSolutions {
        background-color: #465867;
        padding: 100px 0 40px;
    }
    .cProductCard .card-body .card-title {
        min-height: 120px;
    }
}
@media screen and (max-width: 768px) {
    .cHomepageHeader h1 {
        font-size: 44px;
        font-weight: 300;
        color: #fff;
        line-height: 54px;
        margin: 0 0 20px;
        text-align: left;
        margin-bottom: 40px;
    }
    .cProductCard .card-body .card-title {
        min-height: auto;
    }
    .cProductCard {
        padding: 30px 60px 20px;
    }
    .cProductCard .cCardIcon {
        min-height: auto;
        margin-bottom: 15px;
    }
    .cProductsSolutions {
        background-color: #465867;
        padding: 80px 0 20px;
    }
}
@media screen and (max-width: 575px) {
    .cAnalystReports .cFrorresterAPIM,
    .cAnalystReports .cFrorresterCIAM,
    .cAnalystReports .cKuppingercoleCIAM {
        padding: 20px 0 20px;
    }
    .cProductsSolutions {
        padding: 60px 0 15px;
    }
    .cProductCard {
        padding: 30px 30px 20px;
    }
    .cAnalystReports {
        padding-left: 0;
        padding-right: 0;
    }
    .bg-imagesmb {
        padding: 0;
    }

}
@media screen and (max-width: 480px) {
    body {
        background-size: 700%;
    }
    .cProductCard {
        text-align: center;
    }
    .cAnalystReports .cFrorresterAPIM,
    .cAnalystReports .cFrorresterCIAM,
    .cAnalystReports .cKuppingercoleCIAM {
        text-align: center;
    }

    .bg-imagesmb {
        padding: 0;
    }
}

@media (min-width: 768px) {
    .footer-bs .footer-nav,
    .footer-bs .footer-ns,
    .footer-bs .footer-social {
        border-left: solid 1px rgba(255, 255, 255, 0.1);
    }
}
