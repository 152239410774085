
.navbar-logo {
    padding: 15px;
    color: #fff;
    pointer-events: none;
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0;
}

#navbarSupportedContent {
    opacity: 1;
    z-index: 999;
    background-color: #212a32;
    height: calc(100vh - 0px);
    overflow-y: scroll;
    overflow-x: hidden;
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%;
    background-color: #212a32;
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    transition: opacity 0.2s;
}

.navbar-mainbg {
    background-color: #000000;
    padding: 0px;
}

#navbarSupportedContent ul {
    padding: 0px;
    margin: 0px;
}
#navbarSupportedContent ul li a i {
    margin-right: 10px;
}
#navbarSupportedContent li {
    list-style-type: none;
    float: left;
    list-style-type: none;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0);
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, #d7e2de 20%, #d7e2de 50%, #2a2a2a 80%, rgba(0, 0, 0, 0) 100%) 0 0 1 0;
    color: #ffffff;
}
#navbarSupportedContent ul li a {
    color: #ffffff;
    display: block;
    padding: 20px 20px;
    text-decoration: none;
    font-size: 18px;
    transition-duration: 0.6s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    position: relative;
}
#navbarSupportedContent > ul > li.active > a {
    color: #fb4040;
    background-color: transparent;
    transition: all 0.7s;
}
#navbarSupportedContent a:not(:only-child):after {
    content: '\f105';
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 14px;
    font-family: 'Font Awesome 5 Free';
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
    font-weight: 900;
    transition: 0.5s;
}
#navbarSupportedContent .active > a:not(:only-child):after {
    transform: rotate(90deg);
}

.hori-selector {
    display: inline-block;
    position: absolute;
    height: 100%;
    top: 0px;
    left: 0px;
    transition-duration: 0.6s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    background-color: #fff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin-top: 10px;
}
.hori-selector .right,
.hori-selector .left {
    position: absolute;
    width: 25px;
    height: 25px;
    background-color: #fff;
    bottom: 10px;
}
.hori-selector .right {
    right: -25px;
}
.hori-selector .left {
    left: -25px;
}
.hori-selector .right:before,
.hori-selector .left:before {
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #000000;
}
.hori-selector .right:before {
    bottom: 0;
    right: -25px;
}
.hori-selector .left:before {
    bottom: 0;
    left: -25px;
}

.navbar .dropdown-menu div[class*="col"] {
    margin-bottom: 1rem;
}
.navbar .dropdown-menu {
    border: none;
}

/*----------Mobile ---------------*/
.cWSO2MobileNav .back {
    color: #212a32;
    background:#ffffff;
}
.cWSO2MobileNav header {
    height: 60px;
    background-color: #ffffff;
    position: fixed;
    width: 100%;
    top: 0px;
    padding: 10px 0px;
    z-index: 99;
}
.cWSO2MobileNav header .logo-port {
    padding-left: 15px;
    width: 40%;
}
.cWSO2MobileNav header .logo-port img {
    height: 30px;
    /* width: 100px; */
}
/* MINI */
body.mmactive {
    overflow: hidden;
}
.mobile_menu {
    /* display: none; */
    position: relative;
}

.bg-imagesmb{
    background-image:url('https://narada19.github.io/new-design/img/main01.png');
}
.sm_menu_outer.active .mobile_menu {
    display: block;
}
.sm_menu_outer {
    height: calc(100vh - 60px);
    overflow-y: scroll;
    overflow-x: hidden;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: -9;
    width: 100%;
    opacity: 0;
    background-color: #212a32;
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    transition: opacity 0.2s;
    top:52px;
}
.sm_menu_outer.active {
    opacity: 1;
    z-index: 999;
}
.sm_menu_outer .mobile_menu a {
    color: #ffffff;
    display: block;
    padding: 20px 15px;
    /* text-transform: uppercase; */
    text-decoration: none;
}
/*Sub Menu anim */
.sm_menu_outer .mobile_menu li {
    list-style-type: none;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0);
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, #d7e2de 20%, #d7e2de 50%, #2a2a2a 80%, rgba(0, 0, 0, 0) 100%) 0 0 1 0;
    color: #ffffff;
}
/*Hamburger Animation */
#sm_menu_ham {
    cursor: pointer;
    float: right;
    height: 25px;
    position: fixed;
    right: 5px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: all 0.5s ease-in-out 0s;
    -moz-transition: all 0.5s ease-in-out 0s;
    -o-transition: all 0.5s ease-in-out 0s;
    transition: all 0.5s ease-in-out 0s;
    width: 30px;
    top: 15px;
    right: 15px;
    z-index: 9999;
}
#sm_menu_ham span {
    background-color: #212a32;
    border-radius: 5px;
    display: block;
    height: 2px;
    left: 0;
    opacity: 1;
    position: absolute;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: all 0.25s ease-in-out 0s;
    -moz-transition: all 0.25s ease-in-out 0s;
    -o-transition: all 0.25s ease-in-out 0s;
    transition: all 0.25s ease-in-out 0s;
    width: 30px;
}
#sm_menu_ham span:nth-child(1) {
    top: 0;
}
#sm_menu_ham span:nth-child(2),
#sm_menu_ham span:nth-child(3) {
    top: 10px;
}
#sm_menu_ham span:nth-child(4) {
    top: 20px;
}
#sm_menu_ham.open span:nth-child(1) {
    left: 50%;
    top: 18px;
    width: 0;
}
#sm_menu_ham.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
}
#sm_menu_ham.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
#sm_menu_ham.open span:nth-child(4) {
    left: 50%;
    top: 18px;
    width: 0;
}
.sm_menu_outer.accordion .mobile_menu .submenu,
.sm_menu_outer.accordion .mobile_menu li.hasChild ul.submenu {
    display: none;
}
.sm_menu_outer.accordion .mobile_menu .hasChild > .submenu li {
    padding-left: 20px;
}
.sm_menu_outer.accordion .mobile_menu li.active > .submenu {
    right: auto;
}
.sm_menu_outer.accordion .mobile_menu li.hasChild > a {
    position: relative;
}
.sm_menu_outer.accordion .mobile_menu li.hasChild > a::before {
    position: absolute;
    content: '';
    background: no-repeat scroll 50% center / 60% auto ;
    right: 10px;
    top: 20px;
    width: 30px;
    height: 25px;
    transition: all 0.19s ease;
}
.sm_menu_outer.accordion .mobile_menu li.hasChild.active > a::before {
    transform: rotate(89deg);
    transition: all 0.19s ease;
}
.cSubmenuBlock h6 {
    color: #a3a3a3;
    margin-left: 0;
    font-weight: 600;
    /* border-bottom: 3px solid #ff7300; */
    display: inline-block;
    padding-bottom: 8px;
    margin-bottom: 14px;
    margin-top:20px !important;
    padding-top: 0;
    text-transform: uppercase;
    letter-spacing: 0.15rem;
}
.submenu , .mobile_menu {
    margin-left:0;
}
.submenu  {
    background:#35424d;
    padding-top: 20px;
    padding-bottom:20px;
}
.cMobileInput {
    position: absolute;
    z-index: 999999;
    right: 0;
    height: 46px;
    width: 60px;
    background: none;
    background-color: transparent;
    border: none;
    background-size: 30px;
    background-position: center;
    background-repeat: no-repeat;
}
.sm_menu_outer a.cContactButton {
    margin: 0;
    border-radius: 0;
}

.ui.dropdown .menu {
    cursor: auto;
    position: relative;
}
.menu_outer{
    width: -webkit-fill-available;
}
.ui.pointing.dropdown>.menu:after {
    background: #fff0;
}
.ui.dropdown .menu>.item {
    color: #ffffff;
    padding: 20px 15px;
    list-style-type: none;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0);
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, #d7e2de 20%, #d7e2de 50%, #2a2a2a 80%, rgba(0, 0, 0, 0) 100%) 0 0 1 0;
}

.ui.dropdown .menu>.header {
    margin: 1rem 0 .75rem;
    padding: 0 1.14285714rem;
    color: rgba(0,0,0,.85);
    font-size: 0.9em;
    font-weight: 700;
    text-transform: uppercase;
    color: #a3a3a3;
    display: inline-block;
    letter-spacing: 0.15rem;
}
.ui.pointing.dropdown>.menu {
    border-radius: 0;
    background: #35424d;
}
.ui.pointing.upward.dropdown .menu, .ui.top.pointing.upward.dropdown .menu {
    margin: 15px 0 .78571429rem;
}

.ui.dropdown .menu>.item {
    position: relative;
    cursor: pointer;
    display: block;
    border: none;
    height: auto;
    text-align: left;
    border-top: none;
    line-height: 1em;
    color: rgb(255 255 255 / 87%);
    padding: .78571429rem 1.14285714rem!important;
    font-size: 1rem;
    text-transform: none;
    font-weight: 400;
    box-shadow: none;
    -webkit-touch-callout: none;
}
.btn-cto {
    color: #fff;
    background-color: rgb(183, 34, 42);;
    border-color: #dc3545;
}

/*---------End Mobile-------*/

@media (max-width: 414px) {

    #sm_menu_ham, .cWSO2MobileNav,
    .sm_menu_outer.accordion {
        display: block;
        /*margin-top: 50px;*/
    }

    a.navbar-brand {
        width: 8em;
        padding: 4px 0;
    }

    a.cContactButton {
        border-radius: 3px;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        color: #fff !important;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        display: inline-block;
        padding: 6px 18px;
        background-color: #b7222a;
        margin-top: 0;
        margin-right: 0;
        width: 100%;
    }
    #navbarSupportedContent ul li a {
        padding: 20px 30px;
    }
    .hori-selector {
        margin-top: 0px;
        margin-left: 10px;
        border-radius: 0;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
    }
    .hori-selector .left,
    .hori-selector .right {
        right: 10px;
    }
    .hori-selector .left {
        top: -25px;
        left: auto;
    }
    .hori-selector .right {
        bottom: -25px;
    }
    .hori-selector .left:before {
        left: -25px;
        top: -25px;
    }
    .hori-selector .right:before {
        bottom: -25px;
        left: -25px;
    }
    .btn-close {
        background-color: #dc3545 !important;
        border-color: #dc3545 !important;
    }
}
